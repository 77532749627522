import request from "../utils/request";

export default {
  getCate() {
    return request({
      url: "/article/cate",
      method: "get",
    });
  },
  getArticles(data) {
    return request({
      url: "/article/list",
      method: "get",
      data: data,
    });
  },
  getArticle(id) {
    return request({
      url: "/article/item/"+id,
      method: "get",
    });
  },
  updateCate(data) {
    return request({
      url: "/article/cate",
      method: "post",
      data: data,
    });
  },
  addArticle(data) {
    return request({
      url: "/article",
      method: "post",
      data: data,
    });
  },
  updateArticle(data) {
    return request({
      url: "/article",
      method: "patch",
      data: data,
    });
  },
  deleteArticle(id){
    return request({
      url: "/article/"+id,
      method: "delete",
    });
  }
};
